import { lazy, } from 'react';

const DefaultView = lazy(() => import('../views/DefaultView'));
const NotFoundView = lazy(() => import('../views/NotFoundView'));
const DummyView = lazy(() => import('../views/DummyView'));
const LiveScreens = lazy(() => import('../views/LiveScreens'));
const SelectUserTypeForm = lazy(() => import('../forms/SelectUserTypeForm'))
const DepartmentView = lazy(() => import('../views/DepartmentView'));
const EmployeeView = lazy(() => import('../views/EmployeeView'));
const GroupView = lazy(() => import('../views/GroupView'));
// const AppRestrictionView = lazy(() => import('../views/AppRestrictionView'));
// const BrowserRestrictionView = lazy(() => import('../views/BrowserRestrictionView'));
const UserView = lazy(() => import('../views/UserView'));
const IdleTimeReport = lazy(() => import('../reports/idle_time/IdleTimeReport'));
const StatusSummaryReport = lazy(() => import('../reports/status_summary/StatusSummaryReport'));
const StatusSummaryDetails = lazy(() => import('../reports/status_summary_details/StatusSummaryDetails'));
// const DetailsStatusBreak = lazy(() => import('../reports/break_status/DetailsStatusBreak'));
const UserActivationStatus = lazy(() => import('../reports/user_activation_status/UserActivationStatus'));
const EmployeeActivationStatus = lazy(() => import('../reports/employee_activation_status/EmployeeActivationStatus'));
const SuspiciousActivitiesReport = lazy(() => import('../reports/suspicious_activities_report/SuspiciousActivitiesReport'));
const BlacklistSitesReport = lazy(() => import('../reports/blacklist_sites_report/BlacklistSitesReport'));
const BlacklistAppsReport = lazy(() => import('../reports/blacklist_apps_report/BlacklistAppsReport'));
const EmployeOverallReportProject = lazy(() => import('../reports/employee_project_logs_user/EmployeOverallReportProject'));
const EmployeesActivitySummary = lazy(() => import('../reports/employees_activity_summary/EmployeesActivitySummary'));
const WebAndApplicationUsage = lazy(() => import('../reports/employees_activity_reports/WebAndApplicationUsage'));
const EmployeesTotalHours = lazy(() => import('../reports/employees_total_hours_tracked_reports/EmployeesTotalHours'));


const WebUsageView = lazy(() => import('../views/WebUsageView'));
const AppUsageView = lazy(() => import('../views/AppUsageView'));
// Policies
const GroupPolicy = lazy(() => import('../views/GroupPolicyView'));
const WebUsagePolicy = lazy(() => import('../views/WebUsagePolicyView'));
const AppUsagePolicy = lazy(() => import('../views/AppUsagePolicyView'));
const RemindersSchedulerPolicy = lazy(() => import('../views/RemindersSchedulerPolicyView'));
const SensitiveApplicationPolicy = lazy(() => import(`../views/SensitiveApplicationPolicyView`))
const StatusTypesPolicy = lazy(() => import(`../views/StatusTypesView`))

//Log Details 
const UserLogs = lazy(()=> import('../reports/user_log_report/UserLogReports'))
const EmployeeLogs = lazy(()=>import('../reports/employee_login_reports/EmployeeLoginsReports'))

// Productivity Module
const ProjectView = lazy(() => import('../views/ProjectView'));
const DetailedEmployeeProjectLogs = lazy(() => import('../reports/detailed_employee_project_logs/DetailedEmployeeProjectLogs'));
const EmployeeProjectLogs = lazy(() => import('../reports/employee_project_logs/EmployeeProjectLogs'));

// Productivity Dashboards
const ProductivityDashboard = lazy(() => import('../reports/productivity_dashboard/ProductivityDashboard'));
const EmployeeProductivityDashboard = lazy(() => import('../reports/employee_productivity_dashboard/EmployeeProductivityDashboard'));
const ManagerEmployeeProductivityDashboard = lazy(() => import('../reports/manager_employee_productivity_dashboard/ManagerEmployeeProductivityDashboard'));

// Employee Nav Items
const ProductivityView = lazy(() => import('../views/ProductivityView'));

// Downloads
const Download = lazy(() => import('../views/DownloadView'));

//activity log
const ActivityLogsReportView = lazy(() => import('../views/ActivityLogsReportView'));

const getNonNavItems = () => ([
  {
    id: "default",
    path: "/app/default",
    component: DefaultView,
  },
  {
    id: "404",
    path: "/app/404",
    component: NotFoundView,
  },
  {
    id: "select_user_type",
    path: "/select_user_type",
    component: SelectUserTypeForm,
  },
]);

const getNavItems = () => ([
  {
    id: "home",
    title: "Home",
    children: [
      { id: "dummy", title: "Dummies", icon: "AccountBoxOutlined", path: ["/app/organization/dummies", "/app/organization/dummies/filter/:params?", "/app/organization/dummies/new", "/app/organization/dummies/:id", "/app/organization/dummies/edit/:id"], component: DummyView },
    ]
  },
  {
    id: "productivity",
    title: "Productivity",
    children: [
      {
        id: "productivity_dashboard",
        title: "Dashboard",
        icon: "Web",
        path: [
          "/app/reports/productivity_dashboard",
        ],
        component: ProductivityDashboard,
        accessKey: 'CompanyProductivityDashboard'
      },
      {
        id: "employee_productivity_dashboard",
        title: "Employee",
        icon: "ListAlt",
        path: [
          "/app/reports/manager_employee_productivity_dashboard",
          "/app/reports/employee_productivity_dashboard/:id",
          "/app/reports/employee_productivity_dashboard"
        ],
        component: ManagerEmployeeProductivityDashboard,
        accessKey: 'ManagerEmployeeProductivityDashboard'
      },
    ]
  },
  {
    id: "monitoring",
    title: "Monitoring",
    children: [
      {
        id: "live_screens",
        title: "Live Screens",
        icon: "LiveTv",
        path: [
          "/app/monitoring/live_screens",
        ],
        component: LiveScreens,
        accessKey: 'LiveScreens'
      },
      {
        id: "web_usage",
        title: "Web Usage",
        icon: "Web",
        path: [
          "/app/monitoring/web_usage",
        ],
        component: WebUsageView,
        accessKey: 'WebUsage'
      },
      {
        id: "app_usage",
        title: "App Usage",
        icon: "Apps",
        path: [
          "/app/monitoring/app_usage",
        ],
        component: AppUsageView,
        accessKey: 'AppUsage'
      },
    ]
  },
  {
    id: "maintenance",
    title: "Maintenance",
    children: [
      {
        id: "employees",
        title: "Employees",
        icon: "Group",
        path: [
          "/app/maintenance/employees",
          "/app/maintenance/employees/:id",
          "/app/maintenance/employees/new",
          "/app/maintenance/employees/edit/:id",
        ],
        component: EmployeeView,
        accessKey: 'Employees'
      },
      {
        id: "departments",
        title: "Departments",
        icon: "AccountBalanceOutlined",
        path: [
          "/app/maintenance/departments",
          "/app/maintenance/departments/:id",
          "/app/maintenance/departments/new",
          "/app/maintenance/departments/edit/:id",
        ],
        component: DepartmentView,
        accessKey: 'Departments'
      },
      {
        id: "users",
        title: "Users",
        icon: "VerifiedUser",
        path: [
          "/app/maintenance/users",
          "/app/maintenance/users/:id",
          "/app/maintenance/users/new",
          "/app/maintenance/users/edit/:id",
        ],
        component: UserView,
        accessKey: 'Users'
      },
      {
        id: "groups",
        title: "Groups",
        icon: "Security",
        path: [
          "/app/maintenance/security_groups",
          "/app/maintenance/security_groups/:id",
          "/app/maintenance/security_groups/new",
          "/app/maintenance/security_groups/edit/:id",
        ],
        component: GroupView,
        accessKey: 'Groups'
      },
      {
        id: "projects",
        title: "Projects",
        icon: "Assignment",
        path: [
          "/app/maintenance/projects",
          "/app/maintenance/projects/:id",
          "/app/maintenance/projects/new",
          "/app/maintenance/projects/edit/:id",
        ],
        component: ProjectView,
        accessKey: 'Projects'
      },
      {
        id: "status_types_policy",
        title: "Status Type",
        icon: "AvTimer",
        path: [
          "/app/maintenance/status_types_policy",
          "/app/maintenance/status_types_policy/:id",
          "/app/maintenance/status_types_policy/new",
          "/app/maintenance/status_types_policy/edit/:id",
        ],
        component: StatusTypesPolicy,
        accessKey: 'StatusTypes'
      },
      // {
      //   id: "browser_restrictions",
      //   title: "Browser Restrictions",
      //   icon: "Cancel",
      //   path: [
      //     "/app/maintenance/browser_restrictions",
      //     "/app/maintenance/browser_restrictions/:id",
      //     "/app/maintenance/browser_restrictions/new",
      //     "/app/maintenance/browser_restrictions/edit/:id",
      //   ],
      //   component: BrowserRestrictionView,
      //   accessKey: 'BrowserRestrictions'
      // },
      // {
      //   id: "app_restrictions",
      //   title: "App Restrictions",
      //   icon: "DesktopAccessDisabled",
      //   path: [
      //     "/app/maintenance/app_restrictions",
      //     "/app/maintenance/app_restrictions/:id",
      //     "/app/maintenance/app_restrictions/new",
      //     "/app/maintenance/app_restrictions/edit/:id",
      //   ],
      //   component: AppRestrictionView,
      //   accessKey: 'AppRestrictions'
      // },
      {
        id: "group_policies",
        title: "Group Policies",
        icon: "AccountTree",
        path: [
          "/app/maintenance/security_policies",
          "/app/maintenance/security_policies/:id",
          "/app/maintenance/security_policies/new",
          "/app/maintenance/security_policies/edit/:id",
        ],
        component: GroupPolicy,
        accessKey: 'SecurityGroup'
      },
      {
        id: "web_usage_policy",
        title: "Web Usage Policy",
        icon: "WebAsset",
        path: [
          "/app/maintenance/web_usage_policies",
          "/app/maintenance/web_usage_policies/:id",
          "/app/maintenance/web_usage_policies/new",
          "/app/maintenance/web_usage_policies/edit/:id",
        ],
        component: WebUsagePolicy,
        accessKey: 'WebUsagePolicy'
      },
      {
        id: "app_usage_policy",
        title: "App Usage Policy",
        icon: "Category",
        path: [
          "/app/maintenance/app_usage_policies",
          "/app/maintenance/app_usage_policies/:id",
          "/app/maintenance/app_usage_policies/new",
          "/app/maintenance/app_usage_policies/edit/:id",
        ],
        component: AppUsagePolicy,
        accessKey: 'AppUsagePolicy'
      },
      {
        id: "reminder_scheduler_policy",
        title: "Reminders Scheduler Policy",
        icon: "Category",
        path: [
          "/app/maintenance/reminders_scheduler_policies",
          "/app/maintenance/reminders_scheduler_policies/:id",
          "/app/maintenance/reminders_scheduler_policies/new",
          "/app/maintenance/reminders_scheduler_policies/edit/:id",
        ],
        component: RemindersSchedulerPolicy,
        accessKey: 'RemindersPolicy'
      },
      {
        id: "sensitive_application_policy",
        title: "Application Blurring Policy",
        icon: "Category",
        path: [
          "/app/maintenance/sensitive_app_policies",
          "/app/maintenance/sensitive_app_policies/:id",
          "/app/maintenance/sensitive_app_policies/new",
          "/app/maintenance/sensitive_app_policies/edit/:id",
        ],
        component: SensitiveApplicationPolicy,
        accessKey: 'SensitiveAppPolicy'
      },
      // {
      //   id: "status_types_policy",
      //   title: "Status Type Policy",
      //   icon: "Category",
      //   path: [
      //     "/app/maintenance/status_types_policy",
      //     "/app/maintenance/status_types_policy/:id",
      //     "/app/maintenance/status_types_policy/new",
      //     "/app/maintenance/status_types_policy/edit/:id",
      //   ],
      //   component: StatusTypesPolicy,
      //   accessKey: 'always_show'
      // },
    ]
  },
  {
    id: "reports",
    title: "Reports",
    children: [
      {
        id: "status_summary",
        title: "Status Summary",
        icon: "FormatListBulleted",
        path: [
          "/app/reports/status_summary",
        ],
        component: StatusSummaryReport,
        accessKey: 'StatusSummary'
      },
      {
        id: "status_summary_details",
        title: "Status Summary Details",
        icon: "FormatListBulleted",
        path: [
          "/app/reports/status_details_summary",
        ],
        component: StatusSummaryDetails,
        accessKey: 'StatusSummaryDetails'
      },
      // {
      //   id: "break_status",
      //   title: "Break Status",
      //   icon: "FormatListBulleted",
      //   path: [
      //     "/app/reports/break_status",
      //   ],
      //   component: DetailsStatusBreak,
      //   accessKey: 'always_show'
      // },
      {
        id: "idle_time",
        title: "Idle Time",
        icon: "Timer",
        path: [
          "/app/reports/idle",
        ],
        component: IdleTimeReport,
        accessKey: 'Idle-time'
      },
      {
        id: "suspicious_activities_report",
        title: "Suspicious Activities ",
        icon: "ListAlt",
        path: [
          "/app/reports/suspicious_activities_report",
        ],
        component: SuspiciousActivitiesReport,
        accessKey: 'SuspiciousActivitiesReport'
      },
      {
        id: "blacklist_sites_report",
        title: "Blacklisted Sites",
        icon: "ListAlt",
        path: [
          "/app/reports/blacklist_sites_report",
        ],
        component: BlacklistSitesReport,
        accessKey: 'BlacklistSitesReport'
      },
      {
        id: "blacklist_apps_report",
        title: "Blacklisted Apps",
        icon: "ListAlt",
        path: [
          "/app/reports/blacklist_apps_report",
        ],
        component: BlacklistAppsReport,
        accessKey: 'BlacklistAppsReport'
      },
      {
        id: "user_activation_status",
        title: "User Activation Status",
        icon: "ListAlt",
        path: [
          "/app/reports/user_activation_status",
        ],
        component: UserActivationStatus,
        accessKey: 'UserActivationStatus'
      },
      {
        id: "employee_activation_status",
        title: "Employee Activation Status",
        icon: "ListAlt",
        path: [
          "/app/reports/employee_activation_status",
        ],
        component: EmployeeActivationStatus,
        accessKey: 'EmployeeActivationStatus'
      },
      {
        id: "activity_logs",
        title: "Activity logs",
        icon: "History",
        path: [
          "/app/reports/activity_logs",
          "/app/reports/activity_logs/employees",
          "/app/reports/activity_logs/employees/:page_no/filter/:params?",
          "/app/reports/activity_logs/users",
          "/app/reports/activity_logs/users/:page_no/filter/:params?",

        ],
        component: ActivityLogsReportView,
        accessKey: 'ActivityLogsReportView'
      },
      {
        id: "employee_project_logs_user",
        title: "Employees Project Logs ",
        icon: "ListAlt",
        path: [
          "/app/reports/employee_project_logs_user",
        ],
        component: EmployeOverallReportProject,
        accessKey: 'EmployeeOverallReportLogs'
      },
      {
        id: "employees_activity_summary",
        title: "Activity Summary  ",
        icon: "ListAlt",
        path: [
          "/app/reports/employees_activity_summary",
        ],
        component: EmployeesActivitySummary,
        accessKey: 'EmployeesActivitySummary'
      },
      {
        id: "employees_activity_reports",
        title: "Employees Activity",
        icon: "ListAlt",
        path: [
          "/app/reports/employees_activity_reports",
        ],
        component: WebAndApplicationUsage,
        accessKey: 'WebAndApplicationUsage'
      },
      {
        id: "employees_total_hours_tracked_reports",
        title: "Employees Tracked Hours ",
        icon: "ListAlt",
        path: [
          "/app/reports/employees_total_hours_tracked_reports",
        ],
        component: EmployeesTotalHours,
        accessKey: 'EmployeesTotalHours'
      },
      {
        id: "user_log_reports",
        title: "User Logins  ",
        icon: "ListAlt",
        path: [
          "/app/reports/user_log_report",
        ],
        component: UserLogs,
        accessKey: 'UserLoginReport'
      },
      {
        id: "employee_log_reports",
        title: "Employee logins  ",
        icon: "ListAlt",
        path: [
          "/app/reports/employee_log_report",
        ],
        component: EmployeeLogs,
        accessKey: 'EmployeeLoginReports'
      },
    ]
  },
  {
    id: "downloads",
    title: "Downloads",
    children: [
      {
        id: "download_desktop_app",
        title: "Download Desktop App",
        icon: "ListAlt",
        path: [
          "/app/downloads/desktop_app",
        ],
        component: Download,
        accessKey: 'always_show'
      },
    ]
  },
]);

const getEmployeeNavItems = () => ([
  {
    id: "productivity",
    title: "Productivity",
    children: [
      {
        id: "employee_productivity_dashboard",
        title: "Dashboard",
        icon: "Web",
        path: [
          "/app/reports/employee_productivity_dashboard",
        ],
        // TODO: access key
        component: EmployeeProductivityDashboard,
        accessKey: 'EmployeeDashboard'
      },
      {
        id: "productivity",
        title: "My Desk Productivity",
        icon: "LiveTv",
        path: [
          "/app/monitoring/productivity",
        ],
        accessKey: 'EmployeeProductivity',
        component: ProductivityView,
      },
    ]
  },
  {
    id: "productivity_reports",
    title: "Reports",
    children: [
      {
        id: "employee_project_logs",
        icon: "FormatListNumberedRtl",
        title: "My Project Logs",
        path: [
          "/app/reports/employee_project_logs",
        ],
        component: EmployeeProjectLogs,
        // TODO: Access Key
        accessKey: 'EmployeeProjectLogs'
      },
      {
        id: "detailed_employee_project_logs",
        icon: "FormatListNumberedRtl",
        title: "My Detailed Project Logs",
        path: [
          "/app/reports/detailed_employee_project_logs",
        ],
        component: DetailedEmployeeProjectLogs,
        // TODO: Access Key
        accessKey: 'EmployeeDetailedLogs'
      },
    ]
  },
  {
    id: "downloads",
    title: "Downloads",
    children: [
      {
        id: "download_desktop_app",
        title: "Download Desktop App",
        icon: "ListAlt",
        path: [
          "/app/downloads/desktop_app",
        ],
        component: Download,
        accessKey: 'always_show'
      },
    ]
  },
])

export {
  getNavItems,
  getNonNavItems,
  getEmployeeNavItems,
}
